<template>
  <account-flyout
    :open="open"
    :custom-classes="['account-wrapper']"
    :trap-tab-inside-widget="true"
    :user="state.reactiveUser"
    :non-member-variation="nonMemberVariation"
    :account-sidebar-experience="accountSidebarExperience"
    @closed="closed"
    @on-escape="onEscape"
  />
</template>

<script>
import { AccountFlyout } from '@rei/account-flyout';
import { addChangeListener } from '@rei/session';
import mitt from 'mitt';
import { reactive, ref } from 'vue';

const accountEventBus = mitt();
const gNav = document.querySelector('.gnav');
const isWhiteNav = gNav !== null;

export default {
  name: 'AccountFlyoutComponent',
  components: { AccountFlyout },
  props: {
    user: {
      type: Object,
      default: null,
    },
    nonMemberVariation: {
      type: String,
      default: 'control',
    },
    accountSidebarExperience: {
      type: String,
      default: 'control',
    },
  },
  setup(props) {
    const open = ref(false);
    const state = reactive({
      reactiveUser: props.user,
      accountListener: null,
    });

    const addUserListener = () => {
      state.accountListener = addChangeListener((user) => {
        state.reactiveUser = user;
      });
    };

    const destroyUserListener = () => {
      if (state.accountListener) {
        state.accountListener.unsubscribe();
        state.accountListener = null;
      }
    };

    const navbarWrapper = document.querySelector("[data-ui='site-navigation']");
    const accountDropdown = document.querySelector('.account-dropdown');
    const accountToggleButton = document.querySelector(
      "[data-ui='site-header-action-local-account']",
    );

    function toggle() {
      open.value = !open.value;
    }

    function handleClose(escapeKeyPressed) {
      const isFocusOnBody = document.activeElement.tagName === 'BODY';

      open.value = false;
      accountToggleButton.setAttribute('aria-expanded', 'false');
      navbarWrapper.style.overflowY = 'initial';

      window?.metrics?.link({ linkName: 'login_module:close' });

      if (isFocusOnBody || escapeKeyPressed) {
        accountToggleButton.focus();
      }

      if (isWhiteNav) {
        gNav.setAttribute('data-account', 'false');
        document.body.classList.remove('no-scroll');
      }
    }

    function toggleButtonHandler(event) {
      event.preventDefault();
      event.stopPropagation();

      const { clientWidth } = window.document.body;

      window?.metrics?.link({ linkName: 'login_module:open' });

      if (clientWidth < 992 && props.accountSidebarExperience === 'control') {
        window.location.href = '/YourAccountInfoInView';
      } else {
        toggle();
      }

      document.body.classList.remove('sign-in-open');
      accountToggleButton.setAttribute('aria-expanded', open.value ? 'true' : 'false');
      navbarWrapper.style.overflowY = open.value && clientWidth < 992 ? 'hidden' : 'initial';
      accountDropdown.style.display = open.value ? 'block' : 'none';

      if (isWhiteNav) {
        gNav.setAttribute('data-account', open.value ? 'true' : 'false');

        if (open.value) {
          document.body.classList.add('no-scroll');
        } else {
          document.body.classList.remove('no-scroll');
        }
      }
    }

    function clickOutsideHandler(event) {
      // Avoid closing component if user clicked on any of those elements
      const excludeElements = [
        'login-module-my-account',
        'login-module-purchase-history',
        'login-module-your-lists',
        'login-module-sign-out',
        'site-header-action-local-account',
      ];

      if (
        !open.value ||
        excludeElements.includes(event.target.getAttribute('data-ui')) ||
        accountToggleButton.contains(event.target)
      )
        return;

      if (!accountDropdown.contains(event.target) && props.accountSidebarExperience === 'control') {
        accountEventBus.emit('closed');
      }
    }

    function closed() {
      accountEventBus.emit('closed');
    }

    function onEscape() {
      accountEventBus.emit('closed', 'escape key was pressed');
    }

    return {
      open,
      accountToggleButton,
      toggle,
      handleClose,
      toggleButtonHandler,
      clickOutsideHandler,
      closed,
      onEscape,
      state,
      addUserListener,
      destroyUserListener,
    };
  },
  mounted() {
    this.addUserListener();
    accountEventBus.on('closed', (escKeyPressed) => this.handleClose(escKeyPressed));

    this.accountToggleButton.setAttribute('aria-expanded', 'false');
    this.accountToggleButton.setAttribute('aria-controls', 'account-flyout');
    this.accountToggleButton.addEventListener('click', this.toggleButtonHandler);
    window.addEventListener('click', this.clickOutsideHandler, true);
  },
  beforeUnmount() {
    this.destroyUserListener();
    accountEventBus.off('closed', this.handleClose);

    this.accountToggleButton.removeEventListener('click', this.toggleButtonHandler);
    window.removeEventListener('click', this.clickOutsideHandler, true);
  },
};
</script>

<style lang="scss" scoped></style>
