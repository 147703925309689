<template>
  <AccountNavButton :user-greeting="userGreeting" :rewards-balance="rewardsBalance" />
</template>

<script>
import { AccountNavButton } from '@rei/account-flyout';
import { addChangeListener } from '@rei/session';
import { reactive } from 'vue';

export default {
  name: 'AccountNavComponent',
  components: { AccountNavButton },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const state = reactive({
      reactiveUser: props.user,
      accountListener: null,
    });

    const addUserListener = () => {
      state.accountListener = addChangeListener((user) => {
        state.reactiveUser = user;
      });
    };
    const destroyUserListener = () => {
      if (state.accountListener) {
        state.accountListener.unsubscribe();
        state.accountListener = null;
      }
    };
    return {
      state,
      addUserListener,
      destroyUserListener,
    };
  },
  computed: {
    userGreeting() {
      return this.state.reactiveUser &&
        this.state.reactiveUser.contact &&
        this.state.reactiveUser.contact.firstName
        ? `Hi, ${this.state.reactiveUser.contact.firstName}`
        : 'My Account';
    },
    rewardsBalance() {
      if (this.state.reactiveUser) {
        if (this.state.reactiveUser.membership?.dividendBalance != null) {
          const {
            membership: { dividendBalance },
          } = this.state.reactiveUser;
          const balance = parseFloat(dividendBalance);
          const formattedBalance = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(balance);
          return formattedBalance;
        }
        if (
          this.state.reactiveUser.membership &&
          this.state.reactiveUser.membership?.dividendBalance != null
        ) {
          const {
            membership: { dividendBalance },
          } = this.state.reactiveUser;
          const balance = parseFloat(dividendBalance);
          const formattedBalance = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(balance);
          return formattedBalance === '$0.00' ? '' : `${formattedBalance}`;
        }
        return '';
      }
      return '';
    },
  },
  mounted() {
    this.addUserListener();
  },
  beforeUnmount() {
    this.destroyUserListener();
  },
};
</script>

<style lang="scss" scoped></style>
